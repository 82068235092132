import React from "react";
import PropTypes from "prop-types";

const Loader = () => {
  return (
    <div className="fixed bg-bodyBg/80 top-0 left-0 w-full h-full z-30 flex items-center justify-center">
      <svg
        class="animate-spin h-20 w-20 text-primary-400"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  LoaderClass: PropTypes.string,
  LoaderWidth: PropTypes.string,
  LoaderBg: PropTypes.string,
  LoaderBorder: PropTypes.string,
  LoaderBorderColor: PropTypes.string,
  LoaderRadius: PropTypes.string,
  LoaderPadding: PropTypes.string,
  LoaderShadow: PropTypes.string,
};
