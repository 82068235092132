import { useState } from "react";
import InputBox from "atoms/InputBox";
import Button from "atoms/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  loginWithFriebase,
  sendOtpService,
  verifyOtpService,
} from "services/login.service";
import { setAuth, setRefreshToken } from "services/identity.service";
import { useRouter } from "next/router";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import Loader from "atoms/Loader";
import { auth, db } from "services/firebase.service";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { isAdmin, redirectToPage } from "lib/utils";
import { signInWithCustomToken } from "firebase/auth";
import LoginOtpContent from "organisms/LoginOtpContent";
import Image from "next/image";
import Toast from "components/Toast";
import useNotification from "hooks/useNotification";
import { toastErrorMessage } from "lib/message";

const login = ({}) => {
  const [otpSent, setOtpSent] = useState(false);
  const [session, setSession] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const [isMobileDisabled, setisMobileDisbaled] = useState(true);
  const [isOtpDisabled, setisOtpDisbaled] = useState(true);
  const { notificationRef, showNotification } = useNotification();

  const router = useRouter();
  const validationSchema = (otpSent) => {
    if (otpSent && session) {
      return yup.object().shape({
        otp: yup
          .string()
          .required("OTP is mandatory")
          .matches(/^[0-9]+$/, "Invalid OTP format")
          .min(4, "Invalid OTP format")
          .max(4, "Invalid OTP format"),
      });
    } else {
      return yup.object().shape({
        mobile: yup
          .string()
          .required("Mobile Number is mandatory")
          .matches(/^[0-9]+$/, "Invalid mobile no")
          .min(10, "Invalid mobile no")
          .max(10, "Invalid mobile no"),
      });
    }
  };

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setError,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema(otpSent)) });

  async function sendOtp() {
    const isValid = await trigger("mobile");

    if (!isValid) return;

    setLoading(true); // Start loading

    const response = await sendOtpService({
      mobileNumber: "+91" + getValues("mobile"),
    });

    const shouldContinue = showNotification(
      response,
      "error",
      toastErrorMessage[response?.statusCode]
    );
    if (!shouldContinue) {
      setLoading(false);
      return;
    }

    const { data, status, errorResponse } = response;

    if (!status) {
      setError("mobile", {
        type: "manual",
        message:
          errorResponse?.message === "Something went wrong"
            ? "Mobile Number not found"
            : "",
      });
    }

    setLoading(false); // Stop loading

    if (data && data.entity) {
      setError("mobile", { type: "manual", message: "" });
      setSession(data.entity.session);
    }
    setOtpSent(true);
  }

  const customTokenLogin = async (data, values) => {
    try {
      if (isAdmin(data?.Role?.name)) {
        const responseResult = await loginWithFriebase(`+91${values.mobile}`);
        await signInWithCustomToken(auth, responseResult?.data?.entity?.token);
        if (responseResult.status) {
          const userSnapshot = await getDoc(
            doc(db, "users", responseResult?.data?.entity?.user?.localId)
          );
          if (!userSnapshot.exists()) {
            await setDoc(
              doc(db, "users", responseResult?.data?.entity?.user?.localId),
              {
                firstName: data && data.name,
                createdAt: serverTimestamp(),
                imageUrl: "",
                lastName: "",
                lastSeen: serverTimestamp(),
                metadata: {
                  userId: data?.id ?? "",
                  patientId: data?.Patient?.id ?? "",
                  role: data.Role?.name ?? null,
                  hcMobile: values?.mobile,
                },
                role: null,
                updatedAt: serverTimestamp(),
              }
            );
          }
          if (userSnapshot.exists()) {
            const userDocRef = doc(
              db,
              "users",
              responseResult?.data?.entity?.user?.localId
            );
            await updateDoc(userDocRef, {
              firstName: data && data.name,
              lastSeen: serverTimestamp(),
              metadata: {
                userId: data?.id ?? "",
                patientId: data?.Patient?.id ?? "",
                role: data.Role?.name ?? null,
                hcMobile: values?.mobile,
              },
              updatedAt: serverTimestamp(),
            });
          }
        }
      }
      return true;
    } catch (error) {
      console.error("Error", error);
      return false;
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const response = await verifyOtpService({
      mobile: "+91" + values.mobile,
      code: values.otp,
      session,
    });

    const { data } = response;

    if (data.code === "InvalidLambdaResponseException") {
      reset({});
      setOtpSent(false);
      setLoading(false);
      setisMobileDisbaled(true);
      return;
    }
    const shouldContinue = showNotification(
      response,
      'error',
      toastErrorMessage[response?.statusCode]
    );
    if (!shouldContinue) {
      setLoading(false);
      return;
    }

    if (data.code === "InvalidParameterException") {
      setError("otp", {
        type: "custom",
        message: "Incorrect OTP. Please enter the correct OTP",
      });
      setLoading(false);
      return;
    }

    if (data?.message === "Incorrect OTP. Please enter the correct OTP") {
      setLoading(false);
    }

    if (data) {
      console.log("🚀 ~ onSubmit ~ data:", data);
      if (data.session) {
        setSession(data.session);
        setError("otp", { type: "custom", message: data.message });
        return;
      }
      const user = {
        id: data.id,
        id_token: data.id_token,
        roleId: data.roleId,
        name: data.name,
        access_token: data.access_token,
        token: data.id_token,
        role: data.Role?.name,
        doctor: data.Doctor,
      };
      setAuth(user);
      setRefreshToken(data.refresh_token);

      const firebaseLoginResponse = await customTokenLogin(data, values);
      //   if (firebaseLoginResponse) {
      const path = redirectToPage(data?.Role?.name);
      return router.push(path);
      //   } else {
      //     removeAuth();
      //     router.push("/login");
      //   }
    }
    setLoading(false);
  };

  const handleMobileChange = (event) => {
    let inputValue = event.target.value;
    const maxLength = 10;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
      event.target.value = inputValue;
    }
    if (inputValue.length === 10) {
      setisMobileDisbaled(false);
    } else {
      setisMobileDisbaled(true);
    }
  };

  const handleOtpChange = (event) => {
    let otpInputValue = event.target.value;
    const maxLength = 4;
    if (otpInputValue.length > maxLength) {
      otpInputValue = otpInputValue.slice(0, maxLength);
      event.target.value = otpInputValue;
    }
    if (otpInputValue.length === 4) {
      setisOtpDisbaled(false);
    }
  };

  return (
    <div className="bg-grey3 h-screen flex flex-col md:flex-row pt-[84px] pb-16 px-4 md:py-[30px] md: pl-0 md:pr-[30px] overflow-y-auto">
      <Toast ref={notificationRef} />
      <div className="w-5/12 hidden md:block">
        <LoginOtpContent />
      </div>
      <div className="block md:hidden text-center mb-6">
        <Image src="/images/logo.svg" width={250} height={35} alt="" />
      </div>

      <form
        className="flex flex-col  justify-center items-center bg-white pt-[54px] pb-[150px] px-4 md:p-10 rounded-[20px] w-full md:w-7/12"
        onSubmit={
          otpSent && session ? handleSubmit(onSubmit) : handleSubmit(sendOtp)
        }
      >
        <Image
          src="/images/icons/faviconLogo.svg"
          width={66}
          height={57}
          alt="logo"
        />

        {!otpSent ? (
          <div className="max-w-[480px] w-full text-center mt-9 md:mt-20">
            <div className="px-2.5 md:px-0">
              <Text className="text-black">Welcome to DailyBloom,</Text>
              <Heading className="text-[26px] md:text-[30px] font-sectionHeading text-grey6 mt-1.5 mb-6">
                Enter your Mobile number
              </Heading>
              <div className="relative">
                {/* <Text className="absolute top-[9px] left-2.5 z-10">+91</Text> */}
                <InputBox
                  variant="ExtraLarge"
                  type="number"
                  placeholder="Enter your mobile number "
                  dbName="mobile"
                  register={register}
                  isError={errors.mobile?.message}
                  errorMessage={errors.mobile?.message}
                  autoComplete="off"
                  onInput={handleMobileChange}
                  onChange={(e) => setValue("mobile", e.target.value)}
                  inputFontSize="text-sm md:text-lg"
                  className="text-center"
                />
              </div>
              <Text
                variant="bodySmall"
                textColor="text-[#00000099]"
                className="mb-8 mt-1.5"
              >
                Will send OTP to this number
              </Text>
            </div>

            <Button
              style="login"
              size="extraLarge"
              label="Continue"
              className="w-full"
              type="button"
              onClick={sendOtp}
              fontWeight="font-medium"
              // disabled={isMobileDisabled}
            />

            {loading && <Loader />}
          </div>
        ) : (
          <div className="max-w-[480px] w-full text-center  mt-9 md:mt-20">
            <div className="px-2.5 md:px-0">
              <Text className="text-black">
                OTP sent to{" "}
                {`+91 ${getValues("mobile").replace(
                  /^(\d{4})\d{4}(\d{2})$/,
                  "$1****$2"
                )}`}
              </Text>
              <Heading className="text-[26px] md:text-[30px] font-sectionHeading text-grey6 mt-1.5 mb-6">
                Enter OTP
              </Heading>
              <InputBox
                variant="ExtraLarge"
                type="number"
                inputFontSize="text-sm md:text-lg"
                className="w-full text-center"
                placeholder="Enter OTP"
                dbName="otp"
                register={register}
                isError={
                  errors.otp?.message &&
                  !["optionality"].includes(errors.otp?.type)
                }
                errorMessage={
                  !["optionality"].includes(errors.otp?.type) &&
                  errors.otp?.message
                }
                onInput={handleOtpChange}
                onChange={(e) => setValue("otp", e.target.value)}
              />
              <Text
                variant="bodySmall"
                className="mb-8 mt-6"
                textColor="text-[#949494]"
              >
                {/* Resend OTP <span className="text-[#00000099]"> 00:28</span>*/}
              </Text>
            </div>
            <Button
              style="login"
              size="extraLarge"
              label="Login"
              type="submit"
              className="w-full mt-8"
              fontWeight="font-medium"
              // disabled={isOtpDisabled}
            />
            {loading && <Loader />}
          </div>
        )}
      </form>
    </div>
  );
};

export default login;
