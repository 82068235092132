import React from "react";
import Image from "next/image";
import Heading from "atoms/Heading";
import Text from "atoms/Text";

const LoginOtpContent = () => {
  return (
    <div className="flex items-center justify-center h-full bg-grey3  pt-[54px] pb-10 px-4 md:p-10 text-center">
      <div className="max-w-[334px] mx-auto">
        <Image
          src="/images/icons/meditation.svg"
          width={164}
          height={164}
          alt=""
        />
        <Heading
          type="h1"
          className="font-sectionHeading text-grey6 pt-8 pb-[175px]"
        >
          Journey to a Happier, Healthier Gut
        </Heading>
        {/* <Text
          fontSize="text-lg"
          className="xl:mb-[100px] 2xl:mb-[175px] mx-auto"
          textColor="text-grey10"
        >
          Thank you for helping us to copy that encourage the Doctor
        </Text> */}
        <Image src="/images/logo.svg" width={250} height={35} alt="" />
      </div>
    </div>
  );
};

export default LoginOtpContent;
