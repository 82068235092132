import { useRef } from 'react';

const useNotification = () => {
    const notificationRef = useRef(null);

    const displayToastMessage = (type, message) => {
        notificationRef.current.displayToast(type, message);
    };

    const showNotification = (response, type, message) => {
        if (response?.statusCode === 429) {
            displayToastMessage(type, message);
            return false;
        }
        return true;
    };

    return { notificationRef, showNotification };
};

export default useNotification;